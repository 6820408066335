import * as React from 'react'
import { render } from 'react-dom'
import './styles/index.scss';

import { App } from './app'

// console.log = console.warn = console.error = console.table = () => {};


render(<App />, document.getElementById('root'))
